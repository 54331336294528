import { Button } from '@mentimeter/ragnar-ui';
import { useTranslation } from 'react-i18next';
import { openPreferenceCenter } from '@mentimeter/onetrust';

export const CookiePreferencesButton = () => {
  const { t } = useTranslation('common');

  return (
    <Button variant="primary" mb="space8" onClick={openPreferenceCenter}>
      {t('common:page_links.open_cookie_preferences')}
    </Button>
  );
};
