import * as React from 'react';
import * as Yup from 'yup';
import { Form, TextInput, Check } from '@mentimeter/ragnar-form';
import { Trans, useTranslation } from 'react-i18next';
import { Meta } from 'src/ui/typography';
import { Button, Link } from 'src/ui/actions';
import type { FormValuesT, StatusT } from './types';

interface PropsT {
  formFields: FormValuesT;
  status: StatusT;
  handleSubscribe: (data: FormValuesT) => void;
  buttonTitle: string;
  consent?: boolean;
}

export const NotifyForm = ({
  formFields,
  status,
  buttonTitle,
  handleSubscribe,
  consent,
}: PropsT) => {
  const { t } = useTranslation('common');

  const { email, name, jobTitle, company } = formFields;

  const hasNameField = 'name' in formFields;
  const hasJobTitleField = 'jobTitle' in formFields;
  const hasCompanyField = 'company' in formFields;

  const generateValidationSchema = () => ({
    email: Yup.string()
      .email(t('notify_form.validation.email_invalid'))
      .required(t('notify_form.validation.email_required'))
      .matches(
        /^(?!.*gmail|.*hotmail|.*yahoo|.*outlook).*$/,
        t('notify_form.validation.work_email_required'),
      )
      .max(150, t('notify_form.validation.email_long')),

    ...(hasNameField && {
      name: Yup.string()
        .required(t('notify_form.validation.name_required'))
        .max(150, t('notify_form.validation.name_long')),
    }),
    ...(hasJobTitleField && {
      jobTitle: Yup.string()
        .required(t('notify_form.validation.job_title_required'))
        .max(150, t('notify_form.validation.job_title_long')),
    }),
    ...(hasCompanyField && {
      company: Yup.string()
        .required(t('notify_form.validation.company_required'))
        .max(150, t('notify_form.validation.company_long')),
    }),
  });

  const validationSchema = Yup.object().shape(generateValidationSchema());

  return (
    <>
      <Form
        initialValues={{
          email,
          name,
          jobTitle,
          company,
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubscribe}
        width="100%"
        alignItems="center"
      >
        {hasNameField && (
          <TextInput name="name" label={t('notify_form.name')} width={1} />
        )}
        <TextInput
          name="email"
          label={t('notify_form.email')}
          width={1}
          disabled={Boolean(email)}
        />
        {hasJobTitleField && (
          <TextInput
            name="jobTitle"
            label={t('notify_form.job_title')}
            width={1}
          />
        )}
        {hasCompanyField && (
          <TextInput
            name="company"
            label={t('notify_form.company')}
            width={1}
          />
        )}
        {consent && (
          <Check
            id="consent"
            name="consent"
            label={t('notify_form.consent')}
            extend={() => ({
              '& label': {
                marginBottom: 0,
              },
            })}
          />
        )}
        <Button
          mb="space8"
          mt="space2"
          type="submit"
          state={status}
          variant="primary"
        >
          {buttonTitle}
        </Button>
      </Form>
      <Meta textAlign="center" mb={['space8', null, 'space0']}>
        <Trans
          components={{
            terms: (
              <Link href="/terms" target="_blank" rel="noopener noreferrer" />
            ),
            policies: (
              <Link
                href="/policies"
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        >
          {t('notify_form.accept_terms_and_policies')}
        </Trans>
      </Meta>
    </>
  );
};
